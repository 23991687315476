import(/* webpackMode: "eager", webpackExports: ["CohesionContext","CohesionProvider","useMonarch","useMonarchRule","useMonarchRequest","useMonarchSlot","useMonarchSlotContext","useMonarchPreampPlacement","useMonarchPreampPlacementContext","usePreamp","usePreampPlacement","usePreampPlacementExists","usePreampAsset","usePreampAssetExists","MonarchSlotContext","MonarchSlot","MonarchPreampPlacementContext","MonarchPreampPlacement"] */ "/workspace/node_modules/@highereducation/cohesion-utils-react/dist/CohesionProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/public/bg-images/edx-b2b.webp");
;
import(/* webpackMode: "eager" */ "/workspace/public/bg-images/edX-homepage-community.webp");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/course-heading-icon.svg");
;
import(/* webpackMode: "eager" */ "/workspace/public/icons/testimonial-icon.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/AccordionContent/index.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/AccordionText/AccordionTextItem/index.module.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/AccordionText/variants/Pathway/index.module.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/AccordionText/variants/TitleTop/index.module.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/AccordionText/variants/TitleLeft/index.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/AdminToolbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Banner/variants/CourseHub.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Breadcrumbs/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Cohesion/ClientOnly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Cohesion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ContentBlock/variants/Default/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ContentBlock/variants/ExpandableBlock.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ContentBlock/variants/ImageRightParallelogram/index.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/CtaLink/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/DynamicProductList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShortCodeDynamicProductListItems","ShortCodeDynamicProductListItem"] */ "/workspace/src/components/CTA/variants/DynamicProductList/shortcode.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/MultiCourse/Content/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/MultiCourse/descriptions/ChangeDescription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/MultiCourse/descriptions/EarnDescription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/MultiCourse/descriptions/LevelUpDescription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/MultiCourse/descriptions/SharpenDescription.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/CTA/variants/MultiCourse/shortCode/content.module.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/CTA/variants/ImageRight/index.module.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/CTA/variants/ImageLeft.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/CTA/variants/Register.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Footer/SecondaryLinks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Hero/variants/BackgroundImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Hero/variants/NoImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/InfoBox/variants/Statistical.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Link/EventLink/EventLink.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Link/LinkWithChildren/LinkWithChildren.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/ColumnLink.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/LinkList/variants/Dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/FlexLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/LinkList/variants/ImagesSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Navigation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/OtBannerLink/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductCard/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductCardListWrapper/variants/ProductCardIconList/index.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ProductCardListWrapper/variants/ProductCardListByUUID/ProductCardListByUUIDClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductCardListWrapper/variants/ProductCardTabsAndPillsByUUID/ProductCardTabsAndPillsByUUIDClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductCardListWrapper/variants/ProductCardTabsByTopic/ProductCardTabsByTopicClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/ProductSearch/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/QuizComponent/variants/DefaultQuizComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Search/SearchClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/Tabs/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/TaxiForm/variants/Default/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/TaxiForm/variants/SingleStep/index.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Testimonials/Testimonial/variants/light/index.module.css");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/workspace/src/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/VideoPlayer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["needsNewPageView"] */ "/workspace/src/utils/tagular/needsNewPageView.ts");
